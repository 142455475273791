import { Icon, IconProps } from '@chakra-ui/react';

export const MicrosoftIcon: React.FC<IconProps> = props => (
  <Icon
    aria-label="microsoft icon"
    data-testid="microsoft-icon"
    width="18px"
    height="18px"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      id="Vector"
      d="M9.07143 0.857143H1.35715V8.57143H9.07143V0.857143Z"
      fill="#F25022"
    />
    <path
      id="Vector_2"
      d="M9.07143 9.42857H1.35715V17.1429H9.07143V9.42857Z"
      fill="#00A4EF"
    />
    <path
      id="Vector_3"
      d="M17.6428 0.857143H9.92856V8.57143H17.6428V0.857143Z"
      fill="#7FBA00"
    />
    <path
      id="Vector_4"
      d="M17.6428 9.42857H9.92856V17.1429H17.6428V9.42857Z"
      fill="#FFB900"
    />
  </Icon>
);
