import { InputProps, Text } from '@chakra-ui/react';
import { forwardRef, useState, useCallback, ChangeEvent } from 'react';

import Input from '~/components/Input';

import {
  INVALID_EMAIL_ERROR_MESSAGE,
  REQUIRED_EMAIL_MESSAGE,
} from './EmailInput.constants';

enum INPUT_STATES {
  UNTOUCHED = 'UNTOUCHED',
  EMPTY = 'EMPTY',
  INVALID = 'INVALID',
  VALID = 'VALID',
}

const ERRORS: Record<INPUT_STATES, string> = {
  EMPTY: REQUIRED_EMAIL_MESSAGE,
  INVALID: INVALID_EMAIL_ERROR_MESSAGE,
  VALID: '‎',
  UNTOUCHED: '‎',
};

const EmailInput = forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }, ref) => {
    const [emailValidation, setEmailValidation] = useState<INPUT_STATES>(
      INPUT_STATES.UNTOUCHED
    );

    const handleInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      e.target.setCustomValidity('');
      setEmailValidation(INPUT_STATES.VALID);
    }, []);
    const handleInvalid = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        e.target.setCustomValidity(' '); // hides the error message
        if (e.target.value === '') {
          if (props.isRequired) {
            setEmailValidation(INPUT_STATES.EMPTY);
          }
          // If email is not required then exit
          return;
        }
        setEmailValidation(INPUT_STATES.INVALID);
      },
      [props.isRequired]
    );

    return (
      <span style={{ display: 'block', width: '100%' }}>
        <Input
          type="email"
          onInput={handleInput}
          onInvalid={handleInvalid}
          pattern="[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,24}$" // 24 is the longest TLD so far
          sx={{
            '&:invalid': {
              borderColor:
                emailValidation === INPUT_STATES.INVALID
                  ? 'error.600'
                  : 'primary.400',
              color:
                emailValidation === INPUT_STATES.INVALID
                  ? 'error.900'
                  : 'primary.500',
              backgroundColor:
                emailValidation === INPUT_STATES.INVALID ? 'error.50' : 'white',
            },
            '&:placeholder-shown': {
              borderColor:
                emailValidation === INPUT_STATES.EMPTY
                  ? 'error.600'
                  : 'primary.400',
              backgroundColor: 'white',
            },
          }}
          ref={ref}
          {...props}
        />
        <Text fontSize="sm" color="error.600">
          {ERRORS[emailValidation]}
        </Text>
      </span>
    );
  }
);

EmailInput.displayName = 'EmailInput';

export default EmailInput;
