import Button from '~/components/Button';
import { GoogleIcon } from '~/components/icons';
import { useAlert } from '~/contexts/alert';
import {
  AlertError,
  getAlertFeedBackError,
} from '~/contexts/alert/alertFeedBackError';

interface GoogleAuthButtonProps {
  googleAuthUrl?: string | AlertError;
  trackId: string;
}
const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  googleAuthUrl,
  trackId,
}) => {
  const { setAlertProps } = useAlert();

  const handleOnClick = (): void => {
    if (typeof googleAuthUrl !== 'object') {
      const width = 500;
      const height = 400;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      const title = 'GOOGLE AUTH WINDOW';
      const url = googleAuthUrl;
      window.open(
        url,
        title,
        `width=${width},height=${height},left=${left},top=${top}`
      );
    } else {
      getAlertFeedBackError(googleAuthUrl, setAlertProps);
    }
  };
  return (
    <>
      <Button
        marginBottom={4}
        width="100%"
        fontSize="md"
        size="lg"
        background="white"
        border="1px solid #1c2128"
        line-height="10px"
        textTransform="none"
        leftIcon={<GoogleIcon boxSize={6} marginTop={1} />}
        onClick={handleOnClick}
        track-id={trackId}
      >
        Continue with Google
      </Button>
    </>
  );
};

export default GoogleAuthButton;
