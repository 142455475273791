import {
  Input as CInput,
  InputProps,
  forwardRef,
  ComponentWithAs,
  useFormControlContext,
} from '@chakra-ui/react';

import theme from '~/styles/theme';

const Input = forwardRef<InputProps, ComponentWithAs<'input', InputProps>>(
  ({ sx = {}, ...props }, ref) => {
    // for this <Input> component used under <FormControl> component, we need to get props like isInvalid from formControl Context Api
    const formControlContext = useFormControlContext();
    const isInvalid =
      props.isInvalid || (formControlContext && formControlContext.isInvalid);
    return (
      <CInput
        _active={isInvalid ? { borderColor: 'secondary.50' } : undefined}
        _hover={
          isInvalid
            ? {
                borderColor: 'error.500',
                backgroundColor: 'error.50',
                outline: `3px solid ${theme.colors.error[50]}`,
                outlineOffset: 0,
              }
            : {
                borderColor: 'primary.500',
              }
        }
        _focus={
          isInvalid
            ? {
                backgroundColor: 'error.50',
                border: `1px solid ${theme.colors.error[500]}`,
                outline: `3px solid ${theme.colors.error[50]}`,
                outlineOffset: 0,
              }
            : undefined
        }
        _placeholder={{
          color: 'primary.300',
        }}
        backgroundColor="transparent"
        borderColor={
          props.value || props.defaultValue ? 'primary.500' : 'primary.400'
        }
        focusBorderColor="secondary.500"
        fontSize={{ base: 'lg', sm: 'md' }}
        paddingX="3"
        paddingY="4"
        rounded="full"
        sx={{
          '&[aria-invalid=true], &[data-invalid], &:focus': {
            boxShadow: 'none',
          },
          '&[aria-invalid=true]:focus, &[aria-invalid=true]:focus:hover': {
            borderColor: 'error.700',
          },
          '&[aria-invalid=true]:hover': {
            borderColor: 'error.600',
          },
          ...sx,
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

export default Input;
