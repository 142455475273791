import {
  IconButton,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { forwardRef, useState, useCallback, ChangeEvent } from 'react';
import { useToggle } from 'react-use';

import Input from '~/components/Input';
import { ClosedEye, EyeIcon } from '~/components/icons';

interface Props extends InputProps {
  onChange: (event: React.ChangeEvent) => void;
  value?: string;
  size?: string;
  errorTextRequired?: string;
  pattern?: string;
  errorTextPattern?: string;
  errors?: string;
}

const PasswordInput = forwardRef<HTMLInputElement, Props>(
  (
    { size, errorTextRequired, pattern, errorTextPattern, errors, ...props },
    ref
  ) => {
    const [isVisible, toggleVisible] = useToggle(false);
    const theme = useTheme();

    const inputSize = size || theme.components.Input.defaultProps.size;
    const inputHeight = theme.components.Input.sizes[inputSize].field.h;

    const [passwordValidation, setPasswordValidation] = useState<
      string | undefined
    >('');

    const handleInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      e.target.setCustomValidity('');
      setPasswordValidation('');
    }, []);
    const handleInvalid = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        e.target.setCustomValidity(' '); // hides the error message
        if (e.target.value === '') {
          if (props.isRequired) {
            setPasswordValidation(errorTextRequired);
          }
          // If email is not required then exit
          return;
        }
        setPasswordValidation(errorTextPattern);
      },
      [errorTextPattern, errorTextRequired, props.isRequired]
    );

    return (
      <>
        <InputGroup>
          <Input
            ref={ref}
            size={size}
            {...props}
            type={isVisible ? 'text' : 'password'}
            onInput={handleInput}
            onInvalid={handleInvalid}
            pattern={pattern}
            borderColor={passwordValidation ? 'error.600' : ''}
            sx={{
              '&:invalid': {
                borderColor: 'error.600',
                color: 'error.900',
                backgroundColor: passwordValidation ? 'error.50' : 'white',
              },
              '&:placeholder-shown': {
                borderColor: passwordValidation ? 'error.600' : 'primary.400',
                backgroundColor: 'white',
              },
            }}
          />
          <InputRightElement height={theme.space[inputHeight]}>
            <IconButton
              aria-label={isVisible ? 'Hide password' : 'Show password'}
              icon={
                isVisible ? (
                  <ClosedEye width="14px" color="primary.400" />
                ) : (
                  <EyeIcon width="14px" color="primary.400" />
                )
              }
              isRound
              onClick={toggleVisible}
              variant="transparent"
            />
          </InputRightElement>
        </InputGroup>
        <Text fontSize="sm" color="error.600">
          {!passwordValidation && errors}
          {passwordValidation}
        </Text>
      </>
    );
  }
);

PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
