import { Tag } from '@chakra-ui/react';

import Button from '~/components/Button';
import { MicrosoftIcon } from '~/components/icons/components/MicrosoftIcon';
import { useAlert } from '~/contexts/alert';
import {
  AlertError,
  getAlertFeedBackError,
} from '~/contexts/alert/alertFeedBackError';

interface MicrosoftAuthButtonProps {
  microsoftAuthUrl?: string | AlertError;
  trackId: string;
}
const MicrosoftAuthButton: React.FC<MicrosoftAuthButtonProps> = ({
  microsoftAuthUrl,
  trackId,
}) => {
  const { setAlertProps } = useAlert();

  const handleOnClick = (): void => {
    if (typeof microsoftAuthUrl !== 'object') {
      const width = 500;
      const height = 400;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      const title = 'MICROSOFT AUTH WINDOW';
      const url = microsoftAuthUrl;
      window.open(
        url,
        title,
        `width=${width},height=${height},left=${left},top=${top}`
      );
    } else {
      getAlertFeedBackError(microsoftAuthUrl, setAlertProps);
    }
  };
  return (
    <>
      <Button
        marginBottom={4}
        width="100%"
        fontSize="md"
        size="lg"
        background="white"
        border="1px solid #1c2128"
        line-height="10px"
        textTransform="none"
        leftIcon={<MicrosoftIcon boxSize={6} marginTop={1} />}
        onClick={handleOnClick}
        track-id={trackId}
      >
        Continue with Microsoft
        <Tag
          backgroundColor="black"
          color="white"
          borderRadius="100"
          paddingX={2}
          marginX={2}
          fontSize="2xs"
          size="sm"
        >
          BETA
        </Tag>
      </Button>
    </>
  );
};

export default MicrosoftAuthButton;
